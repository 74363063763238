<template>
  <section class="login">
    <v-dialog
      v-model="showUserRegister"
      persistent
      :max-width="'480'"
      transition="slide-x-transition"
      :content-class="!mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'"
    >
      <ResetPasswordModal />
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ResetPasswordModal from '@/components/organisms/register/ResetPasswordModal.vue';

export default {
  name: 'ResetPassword',
  components: {
    ResetPasswordModal,
  },
  data() {
    return {
      showUserRegister: true,
    };
  },
  computed: {
    ...mapGetters({}),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({}),
  },
};
</script>
