<template>
  <div class="register-main register-main--spaced">
    <div
      class="fill-width d-flex flex-column justify-center align-center"
      style="margin-bottom: 40px"
    >
      <ModalHeader
        :content-sections="['actions', 'title', 'logo']"
        :title-text="'views.login.password-recovery.title'"
        small-logo
        @back="backMethod()"
      />
      <ModalContent
        v-if="!successfullyReset"
        :content-sections="['subtitle', 'registerInput', 'passValidation']"
        :subtitle-text="'views.reset_password.title'"
        :input-section="inputContent"
        @setValue="setValue"
      />
      <Content
        v-if="successfullyReset"
        :content-sections="['title']"
        :title-text="'views.login.password-recovery.change_complete'"
      />
    </div>
    <CallToActions
      :types="['main']"
      :active-value="successfullyReset ? true : isActive"
      :loader="resetLoading"
      :main-button-text="
        successfullyReset
          ? 'views.login.password-recovery.to_login'
          : 'user_register.register_data.btn.send'
      "
      :spaced="false"
      @continue="changePassword"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import { email, required, requiredIf } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ResetPasswordModal',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  data() {
    return {
      inputContent: [
        // FIXME: Why are there 3 sections but 2 are rendered?
        {
          value: '',
          noInfoError: false,
          placeholder: 'views.login.name.password',
          password: 0,
          show: false,
          inputType: 'password',
        },
        {
          value: '',
          noInfoError: false,
          placeholder: 'views.login.name.password',
          password: 1,
          show: true,
          inputType: 'password',
        },
      ],
      password: '',
      noInfoError: false,
      resetLoading: false,
      successfullyReset: false,
    };
  },
  validations: {
    name: {
      required,
    },
    email: {
      required: requiredIf(function useEmail() {
        return this.authMethod === 'email' && this.loginAssistAction === '';
      }),
      email(emailToTest) {
        return email(emailToTest);
      },
    },
  },
  computed: {
    ...mapGetters({
      resetPasswordStatus: 'authentication/resetPasswordStatus',
    }),
    isActive() {
      return this.password;
    },
  },
  methods: {
    ...mapActions({
      resetPassword: 'authentication/resetPassword',
      success: 'utils/success',
      error: 'utils/error',
      changeLoggedPassword: 'authentication/changePassword',
      setForcedStep: 'userRegister/setForcedStep',
    }),
    changePassword() {
      if (this.successfullyReset) {
        this.setForcedStep({ step: 0 }).then(() => this.$router.push({ name: 'Login' }));
        return null;
      }
      this.resetLoading = true;
      if (!this.password) {
        this.noInfoError = true;
        return this.noInfoError;
      }
      const params = this.$route.query;
      this.$v.$touch();
      if (this.resetPasswordStatus === 'LOGGED-RESET') {
        this.changeLoggedPassword({
          password1: this.password,
          password2: this.password,
          callback: () => {
            this.raiseSnackbar();
            this.resetLoading = false;
          },
        });
      } else {
        this.resetPassword({
          token: params.token,
          newPassword: this.password,
          callback: () => {
            this.raiseSnackbar();
            this.resetLoading = false;
          },
        });
      }
      return null;
    },
    setValue(mainValue, passwordInput, index) {
      if (passwordInput === 1) {
        this.password = mainValue;
      }
      this.inputContent[index].value = mainValue;
      this.inputContent[index].noInfoError = false;
    },
    raiseSnackbar() {
      if (this.resetPasswordStatus === 'OK') {
        this.success(this.$t('views.reset_password.password_changed'));
        this.successfullyReset = true;
      } else {
        this.error(this.$t('views.reset_password.password_not_changed'));
      }
    },
    backMethod() {
      this.$router.push('/login');
    },
  },
};
</script>
